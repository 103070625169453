import React from 'react';

import Checkbox from 'sats-ui-lib/react/checkbox';
import Text from 'sats-ui-lib/react/text';

import CheckoutSummary from 'shared-ui/components/checkout-summary/checkout-summary';
import Discountcode from 'shared-ui/components/discount-code/discount-code';
import Layout from 'shared-ui/components/layout/layout';

import OptionLinks from 'functions/customize-membership/components/option-links/option-links';

import { CustomizeMembership as Props } from './customize-membership.props';

const CustomizeMembership: React.FunctionComponent<Props> = ({
  campaignCode,
  corporateCode,
  checkoutSummary,
  addons,
  layout,
  options = [],
}) => (
  <Layout {...layout}>
    <div className="customize-membership">
      <div className="customize-membership__options">
        {options.map(option => (
          <div
            className="customize-membership__option-category"
            key={option.title}
          >
            <Text
              elementName="h2"
              size={Text.sizes.large}
              theme={Text.themes.emphasis}
            >
              {option.title}
            </Text>
            <OptionLinks {...option.options} />
          </div>
        ))}

        {addons ? (
          <div className="customize-membership__option-category">
            <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
              {addons.title}
            </Text>
            {addons.options.map(({ link, option }) => (
              <div className="customize-membership__addon" key={link}>
                <Checkbox
                  theme={Checkbox.themes.blue}
                  {...option}
                  onClick={() => window.location.assign(link)}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="customize-membership__discounts">
        {campaignCode ? <Discountcode {...campaignCode} /> : null}
        {corporateCode ? <Discountcode {...corporateCode} /> : null}
      </div>
    </div>
    <div className="customize-membership__summary">
      {checkoutSummary ? <CheckoutSummary {...checkoutSummary} /> : null}
    </div>
  </Layout>
);

export default CustomizeMembership;

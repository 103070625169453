import React, { useState, useEffect } from 'react';

import Search from 'sats-ui-lib/react/search';
import Text from 'sats-ui-lib/react/text';

import Employee from 'shared-ui/components/employee/employee';
import { Employee as EmployeeProps } from 'shared-ui/components/employee/employee.props';
import Spinner from 'shared-ui/components/spinner/spinner';

import { replaceQueryParameters } from 'server/query-strings';

import { EmployeeSearch as Props } from './employee-search.props';

const EmployeeSearch: React.FC<Props> = ({
  clubId,
  clubName,
  endpoint,
  href,
  search,
  title,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [employees, setEmployees] = useState<EmployeeProps[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ clubId, searchTerm }),
    });

    if (response.ok) {
      const data = await response.json();

      setEmployees(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (searchTerm) {
      const debounceTimeout = setTimeout(() => {
        handleSearch();
      }, 300);

      return () => clearTimeout(debounceTimeout);
    } else {
      setEmployees([]);
    }
  }, [searchTerm]);

  return (
    <div className="employee-search">
      <Text size={Text.sizes.headline3} theme={Text.themes.emphasis}>
        {title}
      </Text>
      <div className="employee-search__wrapper">
        <Search
          onChangeFunc={e => {
            setSearchTerm(e);
          }}
          {...search}
          value={searchTerm}
        />
        {loading ? (
          <div className="employee-search__spinner">
            <Spinner />
          </div>
        ) : null}
        {employees.length ? (
          <div className="employee-search__results">
            {employees.map(employee => (
              <Employee
                id={employee.id}
                key={employee.name}
                name={employee.name}
                href={replaceQueryParameters(href, {
                  clubName,
                  employeeId: employee.id,
                })}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EmployeeSearch;

import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import SvgElixia from 'sats-ui-lib/react/logos/elixia';
import Sats from 'sats-ui-lib/react/logos/sats';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'shared-ui/components/content-container/content-container';

import { Parked as Props } from './parked.props';

const Parked: React.FunctionComponent<Props> = ({
  description,
  language,
  link,
  list,
  listTitle,
  title,
}) => (
  <div className="parked-page">
    <ContentContainer theme={ContentContainer.themes.narrow}>
      <div className="parked-page__logo">
        {language === 'fi' ? <SvgElixia /> : <Sats />}
      </div>
      <div className="parked-page__summary">
        <Text
          theme={Text.themes.headline}
          elementName="h1"
          size={Text.sizes.headline2}
          italic={true}
        >
          {title}
        </Text>
        <Text>{description}</Text>
        {list.length ? (
          <div className="parked-page__list">
            <Text
              theme={Text.themes.headline}
              elementName="h2"
              size={Text.sizes.large}
              italic={true}
            >
              {listTitle}
            </Text>
            <ul>
              {list.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        ) : null}
        <LinkButton variant={'primary'} {...link} />
      </div>
    </ContentContainer>
    <div className="parked-page__background"></div>
  </div>
);

export default Parked;

import { useMask } from '@react-input/mask';
import React, { useRef, useState } from 'react';

import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Select from 'sats-ui-lib/react/select';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import PhoneInput from 'shared-ui/components/phone-input/phone-input';

import { get } from 'helpers/api-helper';

import { replaceQueryParameters } from 'server/query-strings';

import type { FormInputs as Props } from './form-inputs.types';

const RegistrationInput: React.FC<Props> = ({
  firstName,
  lastName,
  birthDay,
  ssn,
  gender,
  email,
  address,
  postalAreaEndpoint,
  postalAreaName,
  postalCode,
  phone,
}) => {
  const inputRef = useMask({
    mask: 'dd-mm-åååå', // NOTE: Birthday is only used in Norway.
    replacement: { d: /\d/, m: /\d/, å: /\d/ },
    showMask: true,
  });

  const [postalArea, setPostalArea] = useState('');

  const postalCodeValue = useRef('');

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    setPostalArea('');

    // NOTE: We don't care about postal codes shorter than 4 digits, as Norway, Denmark, Sweden and Finland are all 4+
    if (value.length <= 3) {
      return;
    }

    postalCodeValue.current = value;

    get<{ postalArea: string }>(
      replaceQueryParameters(postalAreaEndpoint, { code: value })
    )
      .then(data => {
        if (postalCodeValue.current === value) {
          setPostalArea(data.postalArea);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="form-inputs">
      <TextInput {...firstName} />
      <TextInput {...lastName} />
      {birthDay ? <TextInput ref={inputRef} {...birthDay} /> : null}
      {ssn ? <TextInput {...ssn} /> : null}
      {gender ? <Select {...gender} /> : null}
      <TextInput {...email} />
      <PhoneInput {...phone} />
      <TextInput {...address} />
      <div className="form-inputs__postal-inputs">
        <TextInput {...postalCode} onChange={handlePostalCodeChange} />
        <HiddenInput value={postalArea} name={postalAreaName} />
        {postalArea ? (
          <Text className="form-inputs__postal-area">{postalArea}</Text>
        ) : null}
      </div>
    </div>
  );
};

export default RegistrationInput;

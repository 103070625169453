import ArrowDown from '@sats-group/icons/16/arrow-down';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Collapse from 'react-tiny-collapse';

import HiddenInput from 'sats-ui-lib/react/hidden-input';
import useEscape from 'sats-ui-lib/react/hooks/use-escape';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import type {
  PrefixPickerOption,
  PhoneInput as Props,
} from './phone-input.types';

const PhoneInput: React.FunctionComponent<Props> = ({
  prefixInputName,
  language,
  prefixPicker,
  title,
  ...inputProps
}) => {
  const defaultDisplayValue =
    prefixPicker.find(option => option.defaultValue)?.displayValue || '';
  const defaultDialCode =
    prefixPicker.find(option => option.defaultValue)?.dialCode || '';
  const [displayValue, setDisplayValue] = useState(defaultDisplayValue);
  const [value, setValue] = useState(defaultDialCode);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => setIsDropdownOpen(!isDropdownOpen);

  useEscape(() => setIsDropdownOpen(false));

  const handleDialCodeSelect = (selectedOption: PrefixPickerOption) => {
    setDisplayValue(selectedOption.displayValue);
    setValue(selectedOption.dialCode);
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent,
    selectedOption: PrefixPickerOption
  ) => {
    if (event.key === 'Enter') {
      setDisplayValue(selectedOption.displayValue);
      setValue(selectedOption.dialCode);
      setIsDropdownOpen(false);
    }
  };

  return (
    <fieldset className="phone-input">
      <HiddenInput name={prefixInputName} value={value} />
      {language && title ? (
        <legend className="phone-input__label">
          <Text theme={Text.themes.emphasis} size={Text.sizes.small}>
            {title}
          </Text>
          <Text
            className="phone-input__label-asterisk"
            theme={Text.themes.emphasis}
            size={Text.sizes.small}
          >
            *
          </Text>
        </legend>
      ) : null}
      <div className="phone-input__prefix-picker" ref={ref}>
        <button
          type="button"
          onClick={handleButtonClick}
          className={cn('phone-input__trigger', {
            'phone-input__trigger--open': isDropdownOpen,
          })}
        >
          <p>{displayValue}</p>
          <div
            className={cn('phone-input__icon', {
              'phone-input__icon--flipped': isDropdownOpen,
            })}
          >
            <ArrowDown />
          </div>
        </button>
        <Collapse
          className="phone-input__dropdown"
          isOpen={isDropdownOpen}
          duration={200}
        >
          <div className="phone-input__dropdown-list">
            {prefixPicker.map(dial => (
              <button
                type="button"
                key={dial.displayValue}
                onClick={() => handleDialCodeSelect(dial)}
                onKeyDown={event => handleKeyDown(event, dial)}
                className="phone-input__dropdown-item"
              >
                <Text>{dial.emoji}</Text>
                <Text size={Text.sizes.small}>{dial.name}</Text>
                <Text
                  className="phone-input__dial-code"
                  size={Text.sizes.small}
                >
                  {dial.dialCode}
                </Text>
              </button>
            ))}
          </div>
        </Collapse>
      </div>
      <div className="phone-input__input">
        <TextInput {...inputProps} />
      </div>
    </fieldset>
  );
};

export default PhoneInput;

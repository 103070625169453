export const login = {
  da: {
    title: 'Log ind',
    submitLabel: 'Log ind',
    labels: {
      description:
        'Log ind med dit medlemsnummer eller den e-mail, du har tilmeldt dig.',
      password: 'Adgangskode',
      passwordPlaceholder: 'Adgangskode',
      username: 'Medlemsnummer eller e-mail',
      usernamePlaceholder: 'Medlemsnummer eller e-mail',
    },
  },
  fi: {
    title: 'Kirjaudu sisään',
    submitLabel: 'Kirjaudu sisään',
    labels: {
      description:
        'Kirjaudu sisään jäsennumerollasi tai sähköpostilla, jolla rekisteröidyit.',
      password: 'Salasana',
      passwordPlaceholder: 'Salasana',
      username: 'Käyttäjätunnus',
      usernamePlaceholder: 'Käyttäjätunnus',
    },
  },
  no: {
    title: 'Innlogging',
    submitLabel: 'Logg inn',
    labels: {
      description:
        'Logg inn med medlemsnummeret ditt, eller e-posten du har registrert deg med.',
      forgotPassword: 'Glemt passord?',
      password: 'Passord',
      passwordPlaceholder: 'Skriv passord her',
      username: 'Brukernavn / e-post',
      usernamePlaceholder: 'Skriv inn brukernavn eller e-post her',
    },
  },
  sv: {
    title: 'Logga in',
    submitLabel: 'Logga in',
    labels: {
      description:
        'Logga in med ditt medlemsnummer eller den e-postadress du registrerade dig med.',
      password: 'Fyll i ditt lösenord',
      passwordPlaceholder: 'Lösenord',
      username: 'Fyll i ditt användarnamn',
      usernamePlaceholder: 'Medlems-ID/mailadress',
    },
  },
};

import SvgEdit from '@sats-group/icons/16/edit';
import SvgArrowRight from '@sats-group/icons/18/arrow-right';
import SvgCheck from '@sats-group/icons/18/check';
import cn from 'classnames';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Message from 'sats-ui-lib/react/message/message';
import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from '../content-container/content-container';
import EmployeeSearch from '../employee-search/employee-search';
import Logo from '../logo/logo';

import { Layout as Props } from './layout.props';

const Layout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  clubName,
  language,
  logoHref,
  logoLabel,
  messages = [],
  salesPerson,
  title,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className="layout">
      <div className="layout__header">
        <ContentContainer className="layout__header-content">
          <Logo href={logoHref} label={logoLabel} language={language} />
          <div className="layout__header-content-right">
            {clubName ? <Text>{clubName}</Text> : null}
            {salesPerson && salesPerson.employees.length ? (
              <div className="layout__header-sales-person">
                <Button
                  {...salesPerson.button}
                  variant={Button.variants.secondaryWhite}
                  trailingIcon={<SvgEdit />}
                  size={Button.sizes.small}
                  onClick={() => setModalOpen(true)}
                />
                {isModalOpen ? (
                  <Modal
                    {...salesPerson.modal}
                    onClose={() => setModalOpen(false)}
                  >
                    <div className="layout__header-sales-person-list">
                      {salesPerson.employees.map(employee => {
                        const Element = employee.isSelected ? 'div' : 'a';
                        return (
                          <Element
                            className={cn('layout__header-sales-person-link', {
                              'layout__header-sales-person-link--selected':
                                employee.isSelected,
                            })}
                            key={employee.name}
                            href={
                              employee.isSelected ? undefined : employee.href
                            }
                          >
                            <Text>{employee.name}</Text>
                            {employee.isSelected ? (
                              <SvgCheck />
                            ) : (
                              <SvgArrowRight />
                            )}
                          </Element>
                        );
                      })}
                    </div>
                    <EmployeeSearch {...salesPerson.search} />
                  </Modal>
                ) : null}
              </div>
            ) : null}
          </div>
        </ContentContainer>
      </div>
      <div className="layout__content">
        <ContentContainer className="layout__content-main">
          <Text
            className="layout__content-title"
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>
          {messages.length ? (
            <div className="layout__content-messages">
              {messages.map(message => (
                <Message key={message.text} {...message} />
              ))}
            </div>
          ) : null}
          {children}
        </ContentContainer>
      </div>
    </div>
  );
};

export default Layout;
